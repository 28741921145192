import { decorate, observable, action, runInAction } from "mobx";
import CommonStore from "modules/CommonStore";
import ApiClient from "./api";

class OrdersStore extends CommonStore {
    constructor(rootStore, urlVersion = 1) {
        const apiClient = new ApiClient(rootStore.apiClient, urlVersion);
        super(rootStore, apiClient, rootStore.specifications["Orders"], false);
    }

    isReordering = false;

    async recalcOrder(order, isRefresh = false) {
        try {
            let res;
            if (isRefresh) {
                if (
                    window.confirm(
                        "Подтвердите получение обновлений основных параметров заказа от поставщика услуг и пересчет заказа",
                    )
                ) {
                    res = await this.apiClient.recalcOrder(order.id, isRefresh);
                }
            } else {
                res = await this.apiClient.recalcOrder(order.id, isRefresh);
            }
            return res;
        } catch (err) {
            alert(err);
            return undefined;
        }
    }

    async extendProviderType(providersStore) {
        const providers = await providersStore.fetchList();
        runInAction(() => {
            this.specification.fields.forEach((item, key) => {
                if (item.name === "provider_type") {
                    this.specification.fields[key] = {
                        name: "provider_type",
                        type: "string",
                        formType: "select",
                        link: {
                            list: providers.map((item) => ({ id: item.short_name, name: item.name })),
                        },
                    };
                }
            });
        });
    }
    async extendProviderInfo(ordersProvidersInfo) {
        const { fields } = ordersProvidersInfo.specification;
        runInAction(() => {
            this.specification.fields.forEach((item, key) => {
                if (item.name === "providers_info") {
                    this.specification.fields[key] = {
                        ...this.specification.fields[key],
                        fields,
                    };
                }
            });
        });
    }

    async repost(id) {
        console.log("repost!", id);
        try {
            this.isReordering = true;
            const data = await this.apiClient.repost(id);
            this.fetchList(false, this.filter, this.orderBy, this.page, this.limit, true);
            this.isReordering = false;
        } catch (e) {
            this.isReordering = false;
            console.error("Error: repost order", e);
        }
        return;
    }
}

decorate(OrdersStore, {});

export default OrdersStore;
